import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-orderlist',
  templateUrl: './orderlist.component.html',
  styleUrls: ['./orderlist.component.css']
})
export class OrderlistComponent implements OnInit {
  
  type: string
  constructor(private router: ActivatedRoute, orderService: OrderService) { }

  ngOnInit(): void {
    this.type = this.router.snapshot.params.type;
  }

  getOrders(){

  }

}
