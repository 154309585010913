export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyADA61rHGdEJtk7qyGFZggHb4rxqn6jOwY",
    authDomain: "carry-8a669.firebaseapp.com",
    projectId: "carry-8a669",
    storageBucket: "carry-8a669.appspot.com",
    messagingSenderId: "794633443992",
    appId: "1:794633443992:web:4d7b946770acf8d7e74eed",
    measurementId: "G-7NSEMKQ0GF"
  },
  apiEndPoint: "https://carry.truport.in"
};
